header {
  position: relative;
}
header .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.2em 0;
}
header .container .logo img {
  width: 9em;
}
header .container .links-language {
  display: flex;
}
header .container .links-language .links {
  display: flex;
  align-items: center;
  gap: 2em;
  margin-right: 30px;
}
header .container .links-language .links a li {
  font-size: 18px;
  font-weight: 500;
}
header .container .change-language {
  position: relative;
}
header .container .change-language .global-icon {
  width: 2em;
}
header .container .change-language .langs {
  flex-direction: column;
  position: absolute;
  top: 41px;
  z-index: 999;
}
header .container .change-language .langs button {
  background-color: var(--purple);
  color: #FFF;
  font-size: 17px;
  font-weight: bold;
  border: none;
  width: 5em;
  height: 2.2em;
}
header .container .change-language .langs button:hover {
  cursor: pointer;
  background-color: var(--dark-purple);
}

.small-header .container .small-links-language {
  display: flex;
  align-items: center;
  gap: 1em;
  position: relative;
}
.small-header .container .logo img {
  width: 9em;
}
.small-header .container .bar-icon {
  font-size: 25px;
}
.small-header .container .links {
  position: absolute;
  right: 2em;
  top: 2.5em;
  background-color: var(--purple);
  width: 7em;
  border-radius: 8px;
  z-index: 999;
}
.small-header .container .links a {
  position: relative;
}
.small-header .container .links a li {
  transition: 0.4s;
  color: #FFF;
  padding: 15px 10px;
  font-size: 18px;
}
.small-header .container .links a li:hover {
  background-color: var(--dark-purple);
}/*# sourceMappingURL=header.css.map */