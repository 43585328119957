footer {
  background: linear-gradient(180deg, var(--purple), var(--dark-purple));
  margin-top: 8em;
}
footer .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.2em 0;
}
footer .container .logo img {
  width: 10em;
}
footer .container .logo p {
  color: #fff;
  max-width: 330px;
  margin-top: 10px;
}
footer .container .links ul {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 3em;
}
footer .container .links ul a {
  color: #fff;
}
footer .container .links ul a li {
  font-size: 18px;
}
footer .container .contact {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
footer .container .contact .title h2 {
  color: #FFF;
}
footer .container .contact .btn {
  display: block;
  width: 8em;
  height: 2.3em;
  background-color: var(--green);
}
footer .container .contact .socials {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 0.7em;
}
footer .container .contact .socials a i {
  color: #fff;
  font-size: 27px;
}
footer .copyright {
  background-color: var(--navyBlue);
  color: white;
  text-align: center;
  padding: 7px 0;
}

@media (max-width: 1200px) {
  footer .container {
    margin: 0 50px;
  }
  footer .container .logo p {
    max-width: 220px;
    font-size: 15px;
  }
  footer .container .logo img {
    width: 8.5em;
  }
}
@media (max-width: 1000px) {
  footer .container .links ul {
    gap: 1.8em;
  }
  footer .container .links ul a li {
    font-size: 16px;
  }
  footer .container .contact .title h2 {
    font-size: 28px;
  }
  footer .container .contact .title .first-bracket,
  footer .container .contact .title .second-bracket {
    width: 3.5em;
  }
  footer .container .contact .btn {
    width: 7em;
    height: 2em;
  }
  .btn a {
    font-size: 16px;
  }
}
@media (max-width: 850px) {
  footer .container .links ul {
    display: none;
  }
  footer .container .logo p {
    max-width: 280px;
    font-size: 16px;
  }
  footer .container .logo img {
    width: 10em;
  }
}
@media (max-width: 600px) {
  footer .container {
    flex-direction: column;
    text-align: center;
  }
  footer .container .contact {
    display: flex;
    align-items: center;
    gap: 2em;
    margin-top: 2em;
  }
}/*# sourceMappingURL=footer.css.map */