.services {

    .intro {
        .text {
            p {
                max-width: 100%;
            }
        }
    }

    .some-services {
        padding: 4em 0;

        .container {
            margin: 0 150px;

            .websites-type-list {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
                gap: 3em 4em;
                margin-top: 30px;
                
                .type {
                    border: 2px solid #6130db52;
                    width: fit-content;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    padding: 0em 1em;
                    height: 22em;
                    width: 17em;
                    border-radius: 10px;
    
                    img {
                        width: 6em;
                        background: #6130db52;
                        padding: 12px;
                        border-radius: 15px;
                    }
    
                    h3 {
                        margin: 4px 0 15px;
                        font-size: 22px
                    }
    
                    p {
                        text-align: center;
                        margin-bottom: 15px
                    }
    
                    a {
                        width: 7em;
                        background-color: var(--purple);
                        
                        h3 {
                            height: 2em;
                            font-size: 18px;
                            font-weight: 700;
                            color: #fff;
                            border: none;
                            border-radius: 5px;
                            cursor: pointer;
                        }
                    }
                }


                .fill{
                    border: 0px solid #6130db52;
                    background-color: var(--purple);
                    color: #FFF;

                    img {
                        background: #ffffffcc;
                    }
    
                    a {
                        h3 {
                            color: var(--purple);
                            background-color: #FFF;
                        }
                    }
                }
            }
        }
    }

    .latest-projects {

        .container {
            padding: 7em 0;
            .projects {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
                gap: 6em;
                margin-top: 3em;
                
                .project {
                    width: fit-content;
                    position: relative;

                    .images {

                        img {
                            border-radius: 8px;
                            border: 2px solid var(--navyBlue)
                        }

                        .phone-screen {
                            width: 13em;
                        }

                        .desktop-screen {
                            width: 24em;
                            position: relative;
                            bottom: 13em;
                            left: -3em;
                            z-index: -1;
                        }
                    }

                    .text {
                        position: absolute;
                        top: 15.5em;
                        right: 3em;
                        width: 55%;
                        
                        h4 {
                            font-size: 22px
                        }

                        p {
                            margin-bottom: 10px;
                        }

                        .btn {
                            display: block;
                            width: 8em
                        }
                    }
                }
            }
        }
    }

    .tools {
        padding: 10em 0 5em;

        .container {
            margin: 0 120px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .title {
                margin-bottom: 25px;

                h2 {
                    max-width: 500px;
                    text-align: center;
                }
                b {
                    color: var(--purple);
                    font-weight: 800;
                }
            }
    
            .tools-imgs {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 3em 4em;
                flex-wrap: wrap;
    
                img {
                    width: 7em;
                    height: 7em;
                    padding: 13px;
                    background-color: #5627c86e;
                    border-radius: 10px;
                    transition: 0.4s;

                    &:hover {
                        width: 7.5em;
                        height: 7.5em;
                        background-color: #5727c8be;
                    }
                }
            }
        }
    }

    .frequently-questions {
        padding: 6em 0;

        .container {
            margin: 0;
            .questions-list {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 3em;

                .que {
                    width: 50%;
                    padding-bottom: 10px;
                    border-bottom: 1px solid var(--navyBlue);
                    margin-bottom: 40px;
                    cursor: pointer;

                    .question {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;

                        h3 {
                            font-size: 23px
                        }

                        img {
                            width: 30px;
                        }
                    }

                    .answer {
                        font-size: 17px;
                        max-width: 750px;
                        margin: 8px 0 4px;
                        line-height: 1.7em;
                    }
                }
            }
        }
    }
}

@media (max-width: 1300px) {
    .services .frequently-questions .container .questions-list .que .question h3 {
        max-width: 500px;
    }
    .services .frequently-questions .container .questions-list .que {
        width: 75%;
    }
}

@media (max-width: 800px) {
    .services .frequently-questions .container .questions-list .que .question h3 {
        font-size: 21px;
    }
    .services .frequently-questions .container .questions-list .que .answer {
        font-size: 16px;
        max-width: 520px;
    }
    .services .frequently-questions .container .questions-list .que .question img {
        width: 25px;
    }
}

@media (max-width:1000px) {
    .services .tools .container .tools-imgs {
        gap: 2em 3em;
    }
    
    .services .tools .container .tools-imgs img {
        width: 6em;
        height: 6em;
    }
}

@media (max-width: 700px) {
    .services .some-services .container {
        margin: 0 40px;
    }

    .services .some-services .container .websites-type-list .type { 
        width: 80%;
    }

    .services .tools .container {
        margin: 0 60px;
    }
    .services .tools .container .title h2 {
        font-size: 28px
    }


    .services .latest-projects .container .projects .project {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    .services .latest-projects .container .projects .project .images .phone-screen {
        display: none;
    }
    .services .latest-projects .container .projects .project .images .desktop-screen {
        bottom: 0;
        left: 0;
        width: 85%;
    }
    .services .latest-projects .container .projects .project .text {
        position: relative;
        top: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 1em;
        width: 70%
    }
    .services .latest-projects .container .projects .project .text h4 {
        font-size: 25px;
        margin-bottom: 3px
    }
}

