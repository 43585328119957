@import url("https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
* {
  margin: 0;
  padding: 0;
}

:root {
  --purple: #6533E1;
  --dark-purple: #5627C8;
  --green: #09d889;
  --navyBlue: #1F1B50;
}

.container {
  margin: 0 80px;
}

.disNone {
  display: none;
}

.disBlock {
  display: block;
}

.disFlex {
  display: flex;
}

a {
  text-decoration: none;
  color: #000;
}

ul {
  list-style-type: none;
}

p {
  line-height: 1.5em;
}

.btn {
  width: 6em;
  height: 2em;
  border: none;
  border-radius: 4px;
  background-color: var(--purple);
  color: #fff;
  font-weight: bold;
  position: relative;
  transition: 0.4s;
}
.btn:hover {
  opacity: 0.9;
}
.btn h3 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  font-size: 17px;
  text-align: center;
}

.title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 1.2em;
}
.title .first-bracket, .title .second-bracket {
  width: 4em;
}
.title h2 {
  max-width: -moz-fit-content;
  max-width: fit-content;
  font-size: 32px;
}
.title .second-bracket {
  transform: rotate(180deg);
}

.center-title {
  align-items: center;
  width: 100%;
}

.intro {
  position: relative;
  background: linear-gradient(180deg, #6533E1, #2f1670);
  height: 30em;
  overflow: hidden;
}
.intro .right-bracket, .intro .left-bracket {
  display: flex;
  align-items: center;
  gap: 10px;
}
.intro .right-bracket img, .intro .left-bracket img {
  width: 3.7em;
}
.intro .right-bracket img:last-child, .intro .left-bracket img:last-child {
  transform: rotate(180deg);
}
.intro .right-bracket {
  position: absolute;
  top: -80px;
  right: 10em;
  transform: rotate(-34deg);
}
.intro .left-bracket {
  position: absolute;
  bottom: -80px;
  left: 12em;
  transform: rotate(34deg);
}
.intro .text {
  position: absolute;
  left: 50%;
  top: 46%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #FFF;
}
.intro .text h1 {
  font-size: 6.5em;
}
.intro .text p {
  font-size: 27px;
  max-width: 500px;
  margin-top: -10px;
}

@media (max-width: 1400px) {
  .intro .text h1 {
    font-size: 5.7em;
  }
  .intro .text p {
    font-size: 25px;
  }
  .intro .right-bracket img, .about .intro .left-bracket img {
    width: 3.2em;
  }
}
@media (max-width: 900px) {
  .intro .right-bracket {
    right: 5em;
  }
  .intro .left-bracket {
    left: 5em;
  }
  .intro .right-bracket img, .about .intro .left-bracket img {
    width: 2.7em;
  }
  .intro .text h1 {
    font-size: 4.5em;
  }
  .intro .text p {
    font-size: 21px;
  }
}/*# sourceMappingURL=global-rules.css.map */