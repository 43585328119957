.contact .title-background {
  background: linear-gradient(180deg, #6533E1, #2f1670);
  height: 17em;
  position: relative;
}
.contact .title-background .title {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.contact .title-background .title .first-bracket, .contact .title-background .title .second-bracket {
  width: 3.8em;
}
.contact .title-background .title .first-bracket {
  transform: rotate(-90deg);
}
.contact .title-background .title .second-bracket {
  transform: rotate(90deg);
}
.contact .title-background .title h2 {
  color: #FFF;
  font-size: 3em;
  margin: -70px 0;
}
.contact .socials {
  display: flex;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
  gap: 8em;
  margin-top: 4em;
}
.contact .socials .soc img {
  width: 4em;
}
.contact .form-section {
  padding: 7em 0;
}
.contact .form-section .container {
  display: flex;
  justify-content: space-around;
  gap: 3em;
}
.contact .form-section .container .title p {
  margin-top: 15px;
}
.contact .form-section .container .form {
  position: relative;
}
.contact .form-section .container .form form {
  background-color: var(--dark-purple);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.3em;
  border-radius: 10px;
}
.contact .form-section .container .form form div {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.contact .form-section .container .form form div label {
  color: #FFF;
  margin-bottom: 8px;
}
.contact .form-section .container .form form div input {
  width: 19em;
  height: 2.2em;
  border-radius: 2px;
  border: none;
  outline: none;
  padding-right: 10px;
  font-size: 18px;
}
.contact .form-section .container .form form div textarea {
  width: 19em;
  height: 9em;
  border-radius: 4px;
  border: none;
  outline: none;
  padding: 5px;
  font-size: 18px;
}
.contact .form-section .container .form form [type=submit] {
  width: 7em;
  height: 2.2em;
  font-size: 17px;
  font-weight: bold;
  color: #FFF;
  background-color: var(--green);
  border: none;
  cursor: pointer;
}
.contact .form-section .container .form .bracket-1, .contact .form-section .container .form .bracket-2 {
  position: absolute;
  width: 1.6em;
}
.contact .form-section .container .form .bracket-1 {
  top: -41px;
  right: -10px;
  transform: rotate(-40deg);
}
.contact .form-section .container .form .bracket-2 {
  bottom: -41px;
  left: -10px;
  transform: rotate(130deg);
}

@media (max-width: 1100px) {
  .contact .form-section .container .title h2 {
    font-size: 28px;
  }
  .contact .form-section .container .title .first-bracket,
  .contact .form-section .container .title .second-bracket {
    width: 3.5em;
  }
}
@media (max-width: 850px) {
  .contact .form-section .container {
    flex-direction: column;
    gap: 1em;
    align-items: center;
    margin: 0 0;
  }
}
@media (max-width: 650px) {
  .contact .socials {
    flex-direction: column;
    gap: 4em;
  }
  .contact .socials .soc img {
    width: 3.5em;
  }
  .contact .title-background .title h2 {
    font-size: 2em;
    margin: -45px 0;
    text-align: center;
  }
  .contact .title-background .title .first-bracket,
  .contact .title-background .title .second-bracket {
    width: 2.8em;
  }
}
@media (max-width: 500px) {
  .contact .form-section .container .form form {
    padding: 0.8em;
  }
}/*# sourceMappingURL=contact.css.map */