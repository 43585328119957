.services .intro .text p {
  max-width: 100%;
}
.services .some-services {
  padding: 4em 0;
}
.services .some-services .container {
  margin: 0 150px;
}
.services .some-services .container .websites-type-list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 3em 4em;
  margin-top: 30px;
}
.services .some-services .container .websites-type-list .type {
  border: 2px solid rgba(97, 48, 219, 0.3215686275);
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0em 1em;
  height: 22em;
  width: 17em;
  border-radius: 10px;
}
.services .some-services .container .websites-type-list .type img {
  width: 6em;
  background: rgba(97, 48, 219, 0.3215686275);
  padding: 12px;
  border-radius: 15px;
}
.services .some-services .container .websites-type-list .type h3 {
  margin: 4px 0 15px;
  font-size: 22px;
}
.services .some-services .container .websites-type-list .type p {
  text-align: center;
  margin-bottom: 15px;
}
.services .some-services .container .websites-type-list .type a {
  width: 7em;
  background-color: var(--purple);
}
.services .some-services .container .websites-type-list .type a h3 {
  height: 2em;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.services .some-services .container .websites-type-list .fill {
  border: 0px solid rgba(97, 48, 219, 0.3215686275);
  background-color: var(--purple);
  color: #FFF;
}
.services .some-services .container .websites-type-list .fill img {
  background: rgba(255, 255, 255, 0.8);
}
.services .some-services .container .websites-type-list .fill a h3 {
  color: var(--purple);
  background-color: #FFF;
}
.services .latest-projects .container {
  padding: 7em 0;
}
.services .latest-projects .container .projects {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 6em;
  margin-top: 3em;
}
.services .latest-projects .container .projects .project {
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}
.services .latest-projects .container .projects .project .images img {
  border-radius: 8px;
  border: 2px solid var(--navyBlue);
}
.services .latest-projects .container .projects .project .images .phone-screen {
  width: 13em;
}
.services .latest-projects .container .projects .project .images .desktop-screen {
  width: 24em;
  position: relative;
  bottom: 13em;
  left: -3em;
  z-index: -1;
}
.services .latest-projects .container .projects .project .text {
  position: absolute;
  top: 15.5em;
  right: 3em;
  width: 55%;
}
.services .latest-projects .container .projects .project .text h4 {
  font-size: 22px;
}
.services .latest-projects .container .projects .project .text p {
  margin-bottom: 10px;
}
.services .latest-projects .container .projects .project .text .btn {
  display: block;
  width: 8em;
}
.services .tools {
  padding: 10em 0 5em;
}
.services .tools .container {
  margin: 0 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.services .tools .container .title {
  margin-bottom: 25px;
}
.services .tools .container .title h2 {
  max-width: 500px;
  text-align: center;
}
.services .tools .container .title b {
  color: var(--purple);
  font-weight: 800;
}
.services .tools .container .tools-imgs {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3em 4em;
  flex-wrap: wrap;
}
.services .tools .container .tools-imgs img {
  width: 7em;
  height: 7em;
  padding: 13px;
  background-color: rgba(86, 39, 200, 0.431372549);
  border-radius: 10px;
  transition: 0.4s;
}
.services .tools .container .tools-imgs img:hover {
  width: 7.5em;
  height: 7.5em;
  background-color: rgba(87, 39, 200, 0.7450980392);
}
.services .frequently-questions {
  padding: 6em 0;
}
.services .frequently-questions .container {
  margin: 0;
}
.services .frequently-questions .container .questions-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3em;
}
.services .frequently-questions .container .questions-list .que {
  width: 50%;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--navyBlue);
  margin-bottom: 40px;
  cursor: pointer;
}
.services .frequently-questions .container .questions-list .que .question {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.services .frequently-questions .container .questions-list .que .question h3 {
  font-size: 23px;
}
.services .frequently-questions .container .questions-list .que .question img {
  width: 30px;
}
.services .frequently-questions .container .questions-list .que .answer {
  font-size: 17px;
  max-width: 750px;
  margin: 8px 0 4px;
  line-height: 1.7em;
}

@media (max-width: 1300px) {
  .services .frequently-questions .container .questions-list .que .question h3 {
    max-width: 500px;
  }
  .services .frequently-questions .container .questions-list .que {
    width: 75%;
  }
}
@media (max-width: 800px) {
  .services .frequently-questions .container .questions-list .que .question h3 {
    font-size: 21px;
  }
  .services .frequently-questions .container .questions-list .que .answer {
    font-size: 16px;
    max-width: 520px;
  }
  .services .frequently-questions .container .questions-list .que .question img {
    width: 25px;
  }
}
@media (max-width: 1000px) {
  .services .tools .container .tools-imgs {
    gap: 2em 3em;
  }
  .services .tools .container .tools-imgs img {
    width: 6em;
    height: 6em;
  }
}
@media (max-width: 700px) {
  .services .some-services .container {
    margin: 0 40px;
  }
  .services .some-services .container .websites-type-list .type {
    width: 80%;
  }
  .services .tools .container {
    margin: 0 60px;
  }
  .services .tools .container .title h2 {
    font-size: 28px;
  }
  .services .latest-projects .container .projects .project {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .services .latest-projects .container .projects .project .images .phone-screen {
    display: none;
  }
  .services .latest-projects .container .projects .project .images .desktop-screen {
    bottom: 0;
    left: 0;
    width: 85%;
  }
  .services .latest-projects .container .projects .project .text {
    position: relative;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1em;
    width: 70%;
  }
  .services .latest-projects .container .projects .project .text h4 {
    font-size: 25px;
    margin-bottom: 3px;
  }
}/*# sourceMappingURL=services.css.map */