
header {
    position: relative;
    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1.2em 0;

        .logo {

            img {
                width: 9em;
            }

        }

        .links-language {
            display: flex;

            .links {
                display: flex;
                align-items: center;
                gap: 2em;
                margin-right: 30px;

                a {

                    li {
                        font-size: 18px;
                        font-weight: 500;
                    }
                }
            }
        }

        .change-language {
            position: relative;
             
            .global-icon {
                width: 2em;
            }

            .langs {
                flex-direction: column;
                position: absolute;
                top: 41px;
                z-index: 999;

                button {
                    background-color: var(--purple);
                    color: #FFF;
                    font-size: 17px;
                    font-weight: bold;
                    border: none;
                    width: 5em;
                    height: 2.2em;

                    &:hover {
                        cursor: pointer;
                        background-color: var(--dark-purple)
                    }
                }
            }
        }
    }
}

.small-header {

    .container {

        .small-links-language {
            display: flex;
            align-items: center;
            gap: 1em;
            position: relative;
        }

        .logo {
            img {
                width: 9em
            }
        }
    
        .bar-icon {
            font-size: 25px
        }
    
        .links {
            position: absolute;
            right: 2em;
            top: 2.5em;
            background-color: var(--purple);
            width: 7em;
            border-radius: 8px;
            z-index: 999;
    
            a {
                position: relative;
                li {
                    transition: 0.4s;
                    color: #FFF;
                    padding: 15px 10px;
                    font-size: 18px;
    
                    &:hover {
                        background-color: var(--dark-purple);
                    }
                }
            }
        }
    }
}