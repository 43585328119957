.contact {
        
    .title-background {
        background: linear-gradient(180deg, #6533E1, #2f1670);
        height: 17em;
        position: relative;

        .title {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);

            .first-bracket, .second-bracket {
                width: 3.8em;
            }
            .first-bracket {
                transform: rotate(-90deg);
            }
            .second-bracket {
                transform: rotate(90deg);
            }

            h2 {
                color: #FFF;
                font-size: 3em;
                margin: -70px 0;
            }
        }
    }

    .socials {
        display: flex;
        justify-content: center;
        text-align: center;
        flex-wrap: wrap;
        gap: 8em;
        margin-top: 4em;

        .soc {

            img {
                width: 4em
            }
        }
    }

    .form-section {
        padding: 7em 0;

        .container {
            display: flex;
            justify-content: space-around;
            gap: 3em;

            .title {

                p{
                    margin-top: 15px
                }
            }

            .form {
                position: relative;

                form {
                    background-color: var(--dark-purple);
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 1.3em;
                    border-radius: 10px;
    
                    div {
                        display: flex;
                        flex-direction: column;
                        margin-bottom: 20px;

                        label {
                            color: #FFF;
                            margin-bottom: 8px
                        }
    
                        input {
                            width: 19em;
                            height: 2.2em;
                            border-radius: 2px;
                            border: none;
                            outline: none;
                            padding-right: 10px;
                            font-size: 18px;
                        }

                        textarea {
                            width: 19em;
                            height: 9em;
                            border-radius: 4px;
                            border: none;
                            outline: none;
                            padding: 5px;
                            font-size: 18px;
                        }
                    }

                    [type='submit'] {
                        width: 7em;
                        height: 2.2em;
                        font-size: 17px;
                        font-weight: bold;
                        color: #FFF;
                        background-color: var(--green);
                        border: none;
                        cursor: pointer;
                    }
                }

                .bracket-1, .bracket-2 {
                    position: absolute;
                    width: 1.6em;
                }
                .bracket-1 {
                    top: -41px;
                    right: -10px;
                    transform: rotate(-40deg);
                }
                .bracket-2 {
                    bottom: -41px;
                    left: -10px;
                    transform: rotate(130deg);
                }
            }
        }
    }
}


// Contact
@media (max-width: 1100px) {
    .contact .form-section .container .title h2 {
        font-size: 28px;
    }
    .contact .form-section .container .title .first-bracket,
    .contact .form-section .container .title .second-bracket {
        width: 3.5em;
    }
}
@media (max-width: 850px) {
    .contact .form-section .container {
        flex-direction: column;
        gap: 1em;
        align-items: center;
        margin: 0 0
    }
}
@media (max-width: 650px) {
    .contact .socials {
        flex-direction: column;
        gap: 4em;
    }
    .contact .socials .soc img {
        width: 3.5em;
    }

    .contact .title-background .title h2 {
        font-size: 2em;
        margin: -45px 0;
        text-align: center;
    }
    .contact .title-background .title .first-bracket,
    .contact .title-background .title .second-bracket {
        width: 2.8em;
    }
}

@media (max-width: 500px) {
    .contact .form-section .container .form form {
        padding: 0.8em;
    }
}