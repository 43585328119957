
.home {

    .home-intro {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 5em 0 8em;

        h1 {
            font-size: 4.5em;
            max-width: 800px;
            line-height: 1.3em;

            b {
                font-family: 'Montserrat';
                color: var(--purple);
                text-transform: uppercase;
            }
        }

        p {
            font-size: 22px;
            max-width: 500px;
            margin: 10px 0 15px;
        }

        .btn {
            width: 8em;
            height: 2.2em;
        }

    }

    .about {
        padding: 3em 0;
        
        .container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 0 280px;
            
            .image {
                img {
                    width: 31em;
                }
            }

            .text {
                p {
                    max-width: 490px;
                    margin-bottom: 13px;
                    font-size: 19px;
                }

                .btn {
                    display: block;
                    width: 6em;
                    height: 2.5em;
                }
            }
        }
    }

    .some-features {
        padding: 10em 0;

        .container {
            display: flex;
            flex-direction: column;
            align-items: center;

            h1 {
                max-width: 650px;
                text-align: center;
                margin-bottom: 1.2em;
                font-size: 2.6em;

                b {
                    color: var(--purple);
                }
            }
    
            .features {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
                gap: 1.8em;
    
                .feature {
                    color: #FFF;
                    background-color: var(--purple);
                    width: 24em;
                    height: 18em;
                    border-radius: 8px;
                    padding: 15px;
                    transition: 0.4s;
                    box-shadow: 3px 3px 7px 0px gray;

                    &:first-child {
                        transform: rotate(2deg);
                    }
                    &:nth-child(2) {
                        background-color: var(--green);
                        transform: translateY(-15px);
                    }
                    &:last-child {
                        background-color: var(--dark-purple);
                        transform: rotate(-2deg);
                    }

                    &:hover {
                        transform: scale(1.05)
                    }
                    
                    img {
                        width: 80px;
                    }
    
                    h3 {
                        margin-bottom: 10px;
                        font-size: 21px;
                    }
    
                    p {
                        max-width: 370px;
                        line-height: 1.8em;
                        font-size: 17px;
                    }
                }
            }
        }
    }

    .latest-projects {

        .container {

            .projects {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
                gap: 6em;
                margin-top: 5em;
                
                .project {
                    width: fit-content;
                    position: relative;

                    .images {

                        img {
                            border-radius: 8px;
                            border: 2px solid var(--navyBlue)
                        }

                        .phone-screen {
                            width: 13em;
                        }

                        .desktop-screen {
                            width: 24em;
                            position: relative;
                            bottom: 13em;
                            left: -3em;
                            z-index: -1;
                        }
                    }

                    .text {
                        position: absolute;
                        top: 15.5em;
                        right: 3em;
                        width: 55%;
                        
                        h4 {
                            font-size: 22px
                        }

                        p {
                            margin-bottom: 10px;
                        }

                        .btn {
                            display: block;
                            width: 8.1em;
                            height: 2.2em;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1600px) {
    .home .about .container {
        margin: 0 100px;
        gap: 3.5em;
    }
    .home .about .container .image img {
        width: 28em
    }
}

@media (max-width: 1450px) {
    .home .some-features .container .features {
        gap: 1.8em 2.1em;
    }
    .home .some-features .container .features .feature:nth-child(2) {
        transform: translateY(0px) rotate(-2deg);
    }
    .home .some-features .container .features .feature:last-child {
        background-color: var(--dark-purple);
        transform: rotate(-1deg);
    }

    .home .some-features .container .features .feature:hover {
        transform: scale(1.05);
        cursor: pointer;
    }
}

@media (max-width: 1100px) {
    .home .about .container {
        margin: 0 60px;
    }
    .home .about .container .image img {
        width: 24em
    }

    .home .intro {
        padding: 5em 60px 8em;
    }
    .home .intro h1 {
        font-size: 3.8em;
    }
    .home .intro p {
        font-size: 20px;
    }
}

@media (max-width: 850px) {
    .home .home-intro h1 {
        font-size: 3.5em;
        max-width: 650px;
    }

    .home .about .container {
        flex-direction: column;
    }
    .home .about .container .text {
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    .home .about .container .image {
        width: 100%;
        text-align: center;
    }
    .home .about .container .image img {
        width: 21em;
    }

    .container {
        margin: 0 20px;
    }
    .home .latest-projects .container .projects .project .images .phone-screen {
        width: 11em;
    }
    .home .latest-projects .container .projects .project .images .desktop-screen {
        width: 22em;
        bottom: 9em;
    }

    .home .latest-projects .container .projects .project .text {
        top: 13.5em;
    }
    .home .latest-projects .container .projects .project .text h4 {
        font-size: 20px;
    }
    .home .latest-projects .container .projects .project .text p {
        max-width: 250px;
    }
}

@media (max-width: 700px) {
    .home .home-intro h1 {
        font-size: 3em;
    }

    .home .some-features .container h1 {
        max-width: 600px;
        font-size: 2.0em;
    }
    .home .some-features .container .features .feature {
        width: 23em;
        height: 17em;
        padding: 13px;
    }
    .home .some-features .container .features .feature img {
        width: 75px;
    }
    .home .some-features .container .features .feature h3 {
        font-size: 20px;
    }



    .home .latest-projects .container .projects .project {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    .home .latest-projects .container .projects .project .images .phone-screen {
        display: none;
    }
    .home .latest-projects .container .projects .project .images .desktop-screen {
        bottom: 0;
        left: 0;
        width: 85%;
    }
    .home .latest-projects .container .projects .project .text {
        position: relative;
        top: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 1em;
        width: 70%
    }
    .home .latest-projects .container .projects .project .text h4 {
        font-size: 25px;
        margin-bottom: 3px
    }
}

@media (max-width: 440px) {
    .home .about .container {
        margin: 0 30px;
    }
    .home .about .container .image img {
        width: 19em;
    }


    .home .some-features .container .features .feature {
        width: 21em;
        height: 16.5em;
        padding: 11px;
    }
    .home .some-features .container .features .feature img {
        width: 68px;
    }
    .home .some-features .container .features .feature h3 {
        font-size: 19px;
    }
    .home .some-features .container .features .feature p {
        line-height: 1.7em;
        font-size: 16px;
    }
}