.about {
    .content {
        padding: 5em 0;

        .container {
            margin:0 180px;
            display: flex;
            flex-direction: column;
            gap: 7em;

            .mission , .your-project {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 8em;
    
                .text {
                    
                    p {
                        max-width: 600px;
                        line-height: 1.8em;
                        margin-bottom: 25px;
                        font-size: 18px
                    }
                }
    
                .image {
                    img {
                        width: 29em;
                        border-radius: 10px;
                        box-shadow: 10px 10px 0px 0px var(--green);
                    }
                }
            }
        }
    }

    .features {
        padding: 14em 0;

        .container {

            .features-list {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
                gap: 6em 10em;
                margin-top: 4em;

                .fea {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    text-align: center;
                    width: fit-content;

                    img {
                        width: 9em;
                    }

                    h3 {
                        font-size: 24px;
                        margin: 10px 0
                    }

                    p {
                        font-size: 17px;
                        max-width: 350px;
                        line-height: 1.7em;
                    }
                }
            }
        }
    }
}

@media (max-width: 1600px) {
    .about .content .container {
        margin: 0 80px;
    }

    .about .content .container .mission .text p, .about .content .container .your-project .text p {
        max-width: 550px;
    }
}


@media (max-width: 1200px) {
    .about .content .container {
        margin: 0 60px;
    }

    .about .content .container .sec{
        gap: 4em;
    }

    .about .content .container .title h2 {
        font-size: 28px;
    }
    .about .content .container .title .first-bracket, .about .content .container .title .second-bracket {
        width: 3.5em;
    }
    .about .content .container .sec .text p {
        font-size: 17px;
    }

    .about .content .container .sec .image img {
        width: 25em;
    }
}

@media (max-width: 900px) {
    .about .intro .right-bracket {
        right: 5em;
    }
    .about .intro .left-bracket {
        left: 5em;
    }
    .about .intro .right-bracket img, .about .intro .left-bracket img {
        width: 2.7em;
    }
    .about .intro .text h1 {
        font-size: 4.5em;
    }
    .about .intro .text p {
        font-size: 21px;
    }


    .about .content .container {
        gap: 9em;
    }
    .about .content .container .sec {
        gap: 1em;
        flex-direction: column;
    }
    .about .content .container .your-project {
        flex-direction: column-reverse;
    }
    .about .content .container .sec .image img {
        width: 27em;
    }


    .about .contact .title-background .title h2 {
        font-size: 2.5em;
        margin: -60px 0;
    }
    .about .contact .title-background .title .first-bracket,
    .about .contact .title-background .title .second-bracket {
        width: 3.4em;
    }
}

@media (max-width: 600px) {
    .about .content .container .sec .text p {
        font-size: 16px;
    }
    .about .content .container .sec .image img {
        width: 100%;
    }

    .about .contact .title-background .title h2 {
        font-size: 2em;
        margin: -45px 0;
        text-align: center;
    }
    .about .contact .title-background .title .first-bracket,
    .about .contact .title-background .title .second-bracket {
        width: 2.8em;
    }
}
